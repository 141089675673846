<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Communication</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0">
              <div class="col-12">
                <div class="row margin-0">
                  <div class="col pad-0">
                    <h4 class="text-center">Adhoc Email</h4>
                    <div class="row margin-0 margin-bottom-5">
                      <div class="col-3">
                        <div class="form-group">
                          <label for=""><b>User Type</b></label>
                          <select
                            name=""
                            id=""
                            v-model="payload.user_type"
                            class="form-control form-control-sm"
                          >
                            <option :value="null">All</option>
                            <option value="TEACHER">TEACHER</option>
                            <option value="STUDENT">STUDENT</option>
                          </select>
                          <span class="error" v-if="error && error.user_type">{{
                            error.user_type
                          }}</span>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label for=""><b>Subscription</b></label>
                          <select
                            name=""
                            id=""
                            v-model="payload.plan"
                            class="form-control form-control-sm"
                          >
                            <option :value="null">All</option>
                            <template v-if="plansList && plansList.results">
                              <template
                                v-for="(item, idx) in plansList.results"
                                :key="idx"
                              >
                                <option :value="item.id">
                                  {{ item.title }}
                                </option>
                              </template>
                            </template>
                          </select>
                        </div>
                        <span class="error" v-if="error && error.plan">{{
                          error.plan
                        }}</span>
                      </div>
                      <div class="col-2">
                        <div class="form-group">
                          <label for=""><b>Status</b></label>
                          <select
                            name=""
                            id=""
                            v-model="payload.status"
                            class="form-control form-control-sm"
                          >
                            <option :value="null">All</option>
                            <template
                              v-if="
                                lookupsSelector && lookupsSelector.USER_STATUS
                              "
                            >
                              <template
                                v-for="(item, idx) in lookupsSelector
                                  .USER_STATUS.codes"
                                :key="idx"
                              >
                                <option :value="item.code">
                                  {{ item.description }}
                                </option>
                              </template>
                            </template>
                          </select>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <label for=""><b>Activity Status</b></label>
                          <select
                            name=""
                            id=""
                            v-model="payload.activity_status"
                            class="form-control form-control-sm"
                          >
                            <option :value="null">All</option>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 text-center pad-15">
                        <button
                          @click="searchData()"
                          class="btn btn-success btn-sm margin-right-10"
                        >
                          Search
                        </button>
                        <button
                          @click="
                            searchResult = null;
                            payload = {
                              user_type: null,
                              status: null,
                              plan: null,
                              action_type: null,
                              title: null,
                              description: null,
                              activity_status: null,
                              email: null,
                            };
                          "
                          class="btn btn-light btn-sm"
                        >
                          Clear
                        </button>
                      </div>
                      <div
                        class="col-12 pad-0 pad-top-15"
                        style="border-top: 1px solid #ddd"
                        v-if="searchResult"
                      >
                        <div class="row margin-0">
                          <div class="col-3">
                            <label for=""><b>Target User</b></label>
                            <p>{{ searchResult.total || 0 }}</p>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <label for=""><b>Action Type</b></label>
                              <select
                                name=""
                                id=""
                                v-model="payload.action_type"
                                class="form-control form-control-sm"
                              >
                                <option :value="null">Select</option>
                                <option value="NOTIFICATION">
                                  NOTIFICATION
                                </option>
                                <option value="EMAIL">EMAIL</option>
                              </select>
                            </div>
                          </div>
                          <template v-if="payload.action_type === 'EMAIL'">
                            <div class="col-3">
                              <div class="form-group">
                                <label for=""><b>Email</b></label>
                                <select
                                  name=""
                                  id=""
                                  v-model="payload.email"
                                  class="form-control form-control-sm"
                                >
                                  <option :value="null">All</option>
                                  <option value="COUPONS_EMAIL">
                                    COUPONS EMAIL
                                  </option>
                                  <!-- <option value="COUPONS_EMAIL">COUPONS EMAIL</option> -->
                                </select>
                              </div>
                            </div>
                            <div class="col-3 pad-top-20">
                              <button
                                @click="newData()"
                                :disabled="payload.email === null"
                                class="btn btn-success btn-sm margin-right-10"
                              >
                                Send
                              </button>
                              <button
                                @click="
                                  searchResult = null;
                                  payload = {
                                    user_type: null,
                                    status: null,
                                    plan: null,
                                    action_type: null,
                                    title: null,
                                    description: null,
                                    activity_status: null,
                                    email: null,
                                  };
                                "
                                class="btn btn-light btn-sm"
                              >
                                Reset
                              </button>
                            </div>
                          </template>
                          <template
                            v-if="payload.action_type === 'NOTIFICATION'"
                          >
                            <div class="col-12 pad-0">
                              <div class="row margin-0 justify-content-center">
                                <div class="col-3">
                                  <div class="form-group">
                                    <label for=""><b>Title</b></label>
                                    <input
                                      type="text"
                                      v-model="payload.title"
                                      placeholder="Title"
                                      class="form-control form-control-sm"
                                    />
                                    <span
                                      class="error"
                                      v-if="error && error.title"
                                      >{{ error.title }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-3">
                                  <div class="form-group">
                                    <label for=""><b>Description</b></label>
                                    <input
                                      type="text"
                                      v-model="payload.description"
                                      placeholder="description"
                                      class="form-control form-control-sm"
                                    />
                                    <span
                                      class="error"
                                      v-if="error && error.description"
                                      >{{ error.description }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-3 pad-top-20">
                                  <button
                                    @click="newData()"
                                    :disabled="
                                      payload.title === null ||
                                      payload.description === null
                                    "
                                    class="
                                      btn btn-success btn-sm
                                      margin-right-10
                                    "
                                  >
                                    Send
                                  </button>
                                  <button
                                    @click="
                                      searchResult = null;
                                      payload = {
                                        user_type: null,
                                        status: null,
                                        plan: null,
                                        action_type: null,
                                        title: null,
                                        description: null,
                                        activity_status: null,
                                        email: null,
                                      };
                                    "
                                    class="btn btn-light btn-sm"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      serviceModule: null,
      plansList: null,
      searchResult: null,
      defaultPayload: {
        user_type: null,
        status: null,
        plan: null,
        action_type: null,
        title: null,
        description: null,
        activity_status: null,
        email: null,
      },
    };
  },
  created() {
    this.getRegionsList();
    this.getPlansList();
  },
  methods: {
    getPlansList() {
      this.$cs.plan.list({}).then((res) => {
        this.plansList = res.data;
      });
    },
    searchData() {
      this.$cs.reportGenerator
        .create({
          data: {
            ...this.payload,
            action: "FILTER",
          },
        })
        .then((res) => {
          this.searchResult = res.data;
        });
    },
    newData() {
      this.$cs.reportGenerator
        .create({
          data: {
            ...this.payload,
            action: "SCHEDULE",
          },
        })
        .then((res) => {
          this.$alert("Communication", "Task Created Sucessfully");
          this.payload = {
            user_type: null,
            status: null,
            plan: null,
            activity_status: null,
            email: null,
          };
          this.searchResult = null;
        });
    },
  },
};
</script>
<style lang="stylus" scoped></style>